import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { motion as m } from 'framer-motion';

import currencies from '../../../api/currencies.json';

const ExchangeStep3 = () => {
  const [t] = useTranslation('global');

  const [isCopied, setIsCopied] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');

  const [localStorageExchangeID, setLocalStorageExchangeID] = useState('');
  const [localStorageExchangeTime, setLocalStorageExchangeTime] = useState('');

  const localStorageSecondOption = localStorage.getItem('second-currency') ? JSON.parse(localStorage.getItem('second-currency')) : currencies[22];
  const userWalletAddress = JSON.parse(localStorage.getItem(`${localStorageSecondOption?.shortName.toLowerCase() || 'eth'}-user-address`));
  const navigate = useNavigate();

  // Get LocalStorage ExchangeId and ExchangeTime last values and //? Redirect to exchange if no data
  useEffect(() => {
    let exchangeID = null;
    let exchangeTime = null;

    let id = 0;
    do {
      if (localStorage.getItem(`exchange-${id}`)) {
        id += 1;
      } else {
        break;
      }
    } while (localStorage.getItem(`exchange-${id}`));

    exchangeID = JSON.parse(localStorage.getItem(`exchange-${id - 1}`));
    exchangeTime = JSON.parse(localStorage.getItem(`exchange-${id - 1}`));

    setLocalStorageExchangeID(exchangeID.id);
    setLocalStorageExchangeTime(exchangeTime.time);

    const redirectIfEmpty = () => {
      if (!exchangeID || !exchangeTime || !userWalletAddress) {
        navigate('/exchange');
      }
    };

    redirectIfEmpty();
  }, []);

  const detailsList = [
    {
      name: t('payment.id'),
      value: localStorageExchangeID,
    },
    {
      name: t('payment.deposit-address'),
      value: userWalletAddress,
    },
    {
      name: t('payment.wallet-address'),
      value: localStorageSecondOption.walletAddress,
    },
    {
      name: t('payment.date'),
      value: localStorageExchangeTime,
    },
    {
      name: t('payment.confirmations'),
      value: '1',
    },
  ];

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }} id='step3'>
      <svg className='hero-bg' viewBox='0 0 1400 750' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_1_357)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1519 229.527L1337.84 466.06L1115.48 312.672L1026.18 515.449L617.351 352.655L352.068 609L237.671 406.988L7.03548 561.174L-39 483.951L266.249 279.884L371.81 466.293L598.25 247.482L981.78 400.202L1079.32 178.732L1319.81 344.629L1452.02 172L1519 229.527Z'
            fill='#171717'
            fillOpacity='0.02'
            shapeRendering='crispEdges'
          />
        </g>
        <defs>
          <filter id='filter0_d_1_357' x='-214.2' y='0.800003' width='1908.4' height='787.4' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='87.6' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0.25 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_357' />
            <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_357' result='shape' />
          </filter>
        </defs>
      </svg>
      <div className='container'>
        <div className='exchange-steps final'>
          <div className='exchange-steps__item final'>
            <p>
              <i className='ri-checkbox-circle-fill'></i>
              {t('success.heading')}
            </p>
          </div>
        </div>
        <div className='exchange-details dfb'>
          <h3>{t('payment.details-header')}</h3>
          <ul className='details-list'>
            {detailsList.map((item) => {
              return (
                <li key={item.name}>
                  <span>{item.name}</span>
                  <p>
                    {item.value}
                    <i
                      className='ri-checkbox-multiple-blank-fill mainColored'
                      onClick={() => {
                        navigator.clipboard.writeText(item.value);
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 3000);
                      }}></i>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <NavLink to='/' className='btn main-color-btn'>
          {t('success.button')}
        </NavLink>
      </div>
    </m.section>
  );
};

export default ExchangeStep3;
