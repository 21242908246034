import React from 'react';

import { useTranslation } from 'react-i18next';

const Information = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='information'>
      <div className='container'>
        <h3 className='section-title'>{t('how-it-works.information-heading3')}</h3>
        <div className='information'>
          <div className='information-item dfb'>
            <i className='ri-dashboard-fill mainColored'></i>
            <div className='information-text'>
              <h4>{t('how-it-works.information1-title')}</h4>
              <p>{t('how-it-works.information1-text')}</p>
            </div>
          </div>
          <div className='information-item dfb'>
            <i className='ri-lock-fill mainColored'></i>
            <div className='information-text'>
              <h4>{t('how-it-works.information2-title')}</h4>
              <p>{t('how-it-works.information2-text')}</p>
            </div>
          </div>
          <div className='information-item dfb'>
            <i className='ri-shake-hands-line mainColored'></i>
            <div className='information-text'>
              <h4>{t('how-it-works.information3-title')}</h4>
              <p>{t('how-it-works.information3-text')}</p>
            </div>
          </div>
          <div className='information-item dfb'>
            <i className='ri-timer-line mainColored'></i>
            <div className='information-text'>
              <h4>{t('how-it-works.information4-title')}</h4>
              <p>{t('how-it-works.information4-text')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Information;
