import React from 'react';
import HeaderLanguageSwitch from './HeaderLanguageSwitch';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const HeaderButtons = () => {
  const [t, i18n] = useTranslation('global');
  return (
    <>
      <div className='buttons-wrapper'>
        <NavLink to='/exchange' className='btn main-color-btn'>
          <i className='ri-exchange-fill' style={{ color: '#000' }}></i>
          {t('header.menu5')}
        </NavLink>
        <a href='https://t.me/' className='btn blue-btn' target='_blank'>
          <i className='ri-telegram-fill' style={{ color: '#FFF' }}></i>
          {t('header.menu6')}
        </a>
        <HeaderLanguageSwitch />
      </div>
    </>
  );
};

export default HeaderButtons;
