import currencies from '../../../api/currencies.json';

const getRandomInterval = () => {
  const min = 30000;
  const max = 120000;

  const randomInterval = Math.floor(Math.random() * (max - min) + min);
  return randomInterval;
};

const getRandomTimeAgo = () => {
  const min = 1;
  const max = 60;
  return Math.floor(Math.random() * (max * 20)) + min;
};

const getRandomLength = () => {
  const min = 3;
  const max = 8;
  return Math.floor(Math.random() * (max - min)) + min;
};

const getRandomAmount = () => {
  const min = 0.00001;
  const max = 2;
  return Math.random() * (max - min) + min;
};

const getRandomCurrencies = () => {
  const randomCurrency1 = currencies[Math.floor(Math.random() * currencies.length)];
  const randomCurrency2 = currencies[Math.floor(Math.random() * currencies.length)];

  if (randomCurrency1 == randomCurrency2) {
    return getRandomCurrencies();
  }

  return [randomCurrency1, randomCurrency2];
};

export { getRandomInterval, getRandomTimeAgo, getRandomLength, getRandomAmount, getRandomCurrencies };
