import React from 'react';
import { useTranslation } from 'react-i18next';

function Benefits() {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='benefits'>
      <div className='container'>
        <h3 className='section-title'>{t('benefits.heading3')}</h3>
        <div className='benefits'>
          <div className='benefits-item'>
            <i className='ri-money-dollar-circle-fill mainColored'></i>
            <i className='ri-money-dollar-circle-fill big-icon'></i>
            <div className='benefits-text'>
              <h4>{t('benefits.text1-h4')}</h4>
              <p>{t('benefits.text1-p')}</p>
            </div>
          </div>
          <div className='benefits-item'>
            <i className='ri-user-unfollow-fill mainColored'></i>
            <i className='ri-user-unfollow-fill big-icon'></i>
            <div className='benefits-text'>
              <h4>{t('benefits.text2-h4')}</h4>
              <p>{t('benefits.text2-p')}</p>
            </div>
          </div>
          <div className='benefits-item'>
            <i className='ri-exchange-fill mainColored'></i>
            <i className='ri-exchange-fill big-icon'></i>
            <div className='benefits-text'>
              <h4>{t('benefits.text3-h4')}</h4>
              <p>{t('benefits.text3-p')}</p>
            </div>
          </div>
          <div className='benefits-item'>
            <i className='ri-flashlight-fill mainColored'></i>
            <i className='ri-flashlight-fill big-icon'></i>
            <div className='benefits-text'>
              <h4>{t('benefits.text4-h4')}</h4>
              <p>{t('benefits.text4-p')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
