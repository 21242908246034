// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

import { useTranslation } from 'react-i18next';

function TradingViewWidget() {
  const [t, i18n] = useTranslation('global');
  const container = useRef();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "NASDAQ:AAPL",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "0",
          "locale": "ru",
          "enable_publishing": false,
          "allow_symbol_change": true,
          "support_host": "https://www.tradingview.com"
        }`;
    container.current.appendChild(script);
  }, []);

  return (
    <section id='widget'>
      <div className='container'>
        <h3 className='section-title'>{t('steps.heading3')}</h3>
        <div className='tradingview-widget-container' ref={container} style={{ height: '100%', aspectRatio: '2/1', width: '100%' }}>
          <div className='tradingview-widget-container__widget' style={{ height: 'calc(100% - 32px)', width: '100%' }}></div>
        </div>
      </div>
    </section>
  );
}

export default memo(TradingViewWidget);
