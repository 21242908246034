import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const HeaderMenu = () => {
  const [t, i18n] = useTranslation('global');

  const body = document.querySelector('body');
  const burgerInputRef = document.querySelector('#burger');
  const handleLinkClick = (event) => {
    if (burgerInputRef) {
      burgerInputRef.checked = false;
      if (burgerInputRef.checked == false) {
        body.style.position = 'static';
        body.style.maxWidth = 'auto';
      } else if (burgerInputRef.checked == true) {
        body.style.position = 'fixed';
        body.style.maxWidth = '100%';
      }
    }
  };

  return (
    <nav>
      <ul className='menu-list'>
        <li>
          <NavLink to='/about-us' className='menu-link' onClick={handleLinkClick}>
            {t('header.menu1')}
          </NavLink>
        </li>
        <li>
          <NavLink to='/how-its-work' className='menu-link' onClick={handleLinkClick}>
            {t('header.menu2')}
          </NavLink>
        </li>
        {/* <NavLink><Link to='/defi-swap' className='menu-link' onClick={handleLinkClick}>{t('header.menu3')}</NavLink></li> */}
        <li>
          <NavLink to='/partnership' className='menu-link' onClick={handleLinkClick}>
            {t('header.menu4')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};
export default HeaderMenu;
