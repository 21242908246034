import React from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import '../About/about.css';

const GovermentRequests = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} id='about'>
      <div className='container'>
        <div className='about-image'>
          <h1>{t('gov.h1')}</h1>
          <img src='/about-us-bg.jpg' alt='Image with no sense with heading on it' />
        </div>
        <div className='container-890'>
          <div className='about-text'>
            <h2 className='bigger'>{t('gov.heading1')}</h2>
            <p>{t('gov.paragraph1')}</p>
            <p>{t('gov.paragraph1-1')}</p>
            <h2 className='bigger'>{t('gov.heading2')}</h2>
            <p>{t('gov.paragraph2')}</p>
            <p>{t('gov.paragraph2-1')}</p>
            <h2 className='bigger'>{t('gov.heading3')}</h2>
            <p>{t('gov.paragraph3')}</p>
            <ul className='default-list'>
              <li>{t('gov.paragraph3-1_li1')}</li>
              <li>{t('gov.paragraph3-1_li2')}</li>
              <li>{t('gov.paragraph3-1_li3')}</li>
              <li>{t('gov.paragraph3-1_li4')}</li>
              <li>{t('gov.paragraph3-1_li5')}</li>
              <li>{t('gov.paragraph3-1_li6')}</li>
            </ul>
            <p>{t('gov.paragraph3-2')}</p>
            <ul className='default-list'>
              <li>{t('gov.paragraph3-2_li1')}</li>
              <li>{t('gov.paragraph3-2_li2')}</li>
              <li>{t('gov.paragraph3-2_li3')}</li>
              <li>{t('gov.paragraph3-2_li4')}</li>
            </ul>
            <h2 className='bigger'>{t('gov.heading4')}</h2>
            <p>{t('gov.paragraph4')}</p>
            <h2 className='bigger'>{t('gov.heading5')}</h2>
            <p>{t('gov.paragraph5')}</p>
            <p>{t('gov.paragraph5-2')}</p>
          </div>
        </div>
      </div>
    </m.section>
  );
};

export default GovermentRequests;
