import React from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import '../About/about.css';

const AmlKyc = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} id='about'>
      <div className='container'>
        <div className='about-image'>
          <h1>AML / KYC</h1>
          <img src='/about-us-bg.jpg' alt='Image with no sense with heading on it' />
        </div>
        <div className='container-890'>
          <div className='about-text'>
            <h2 className='bigger'>{t('aml-kyc.heading1')}</h2>
            <p>{t('aml-kyc.paragraph1')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading2')}</h2>
            <p>{t('aml-kyc.paragraph2')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading3')}</h2>
            <p>{t('aml-kyc.paragraph3')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading4')}</h2>
            <p>{t('aml-kyc.paragraph4')}</p>
            <p>{t('aml-kyc.paragraph4-1')}</p>
            <p>{t('aml-kyc.paragraph4-2')}</p>
            <p>{t('aml-kyc.paragraph4-3')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading5')}</h2>
            <p>{t('aml-kyc.paragraph5')}</p>
            <p>{t('aml-kyc.paragraph5-1')}</p>
            <ul className='default-list'>
              <li>{t('aml-kyc.paragraph5-1_li1')}</li>
              <li>{t('aml-kyc.paragraph5-1_li2')}</li>
              <li>{t('aml-kyc.paragraph5-1_li3')}</li>
              <li>{t('aml-kyc.paragraph5-1_li4')}</li>
              <li>{t('aml-kyc.paragraph5-1_li5')}</li>
            </ul>
            <p>{t('aml-kyc.paragraph5-2')}</p>
            <p>{t('aml-kyc.paragraph5-3')}</p>
            <p>{t('aml-kyc.paragraph5-4')}</p>
            <p>{t('aml-kyc.paragraph5-5')}</p>
            <p>{t('aml-kyc.paragraph5-6')}</p>
            <p>{t('aml-kyc.paragraph5-7')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading6')}</h2>
            <p>{t('aml-kyc.paragraph6')}</p>
            <p>{t('aml-kyc.paragraph6-1')}</p>
            <ul className='default-list'>
              <li>{t('aml-kyc.paragraph6-1_li1')}</li>
              <li>{t('aml-kyc.paragraph6-1_li2')}</li>
              <li>{t('aml-kyc.paragraph6-1_li3')}</li>
              <li>{t('aml-kyc.paragraph6-1_li4')}</li>
              <li>{t('aml-kyc.paragraph6-1_li5')}</li>
              <li>{t('aml-kyc.paragraph6-1_li6')}</li>
            </ul>
            <p>{t('aml-kyc.paragraph6-2')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading7')}</h2>
            <p>{t('aml-kyc.paragraph7')}</p>
            <ul className='default-list'>
              <li>{t('aml-kyc.paragraph7-1_li1')}</li>
              <li>{t('aml-kyc.paragraph7-1_li2')}</li>
            </ul>
            <h2 className='bigger'>{t('aml-kyc.heading8')}</h2>
            <p>{t('aml-kyc.paragraph8')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading9')}</h2>
            <p>{t('aml-kyc.paragraph9')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading10')}</h2>
            <p>{t('aml-kyc.paragraph10')}</p>
            <ul className='default-list'>
              <li>{t('aml-kyc.paragraph10-1_li1')}</li>
              <li>{t('aml-kyc.paragraph10-1_li2')}</li>
              <li>{t('aml-kyc.paragraph10-1_li3')}</li>
              <li>{t('aml-kyc.paragraph10-1_li4')}</li>
            </ul>
            <h2 className='bigger'>{t('aml-kyc.heading11')}</h2>
            <p>{t('aml-kyc.paragraph11')}</p>
            <p>{t('aml-kyc.paragraph11-1')}</p>
            <p>{t('aml-kyc.paragraph11-2')}</p>
            <p>{t('aml-kyc.paragraph11-3')}</p>
            <p>{t('aml-kyc.paragraph11')}</p>
            <h2 className='bigger'>{t('aml-kyc.heading12')}</h2>
            <p>{t('aml-kyc.paragraph12')}</p>

            {/* <ul className="default-list">
              <li>{t("aml-kyc.paragraph5-1_li1")}</li>
              <li>{t("aml-kyc.paragraph5-1_li2")}</li>
              <li>{t("aml-kyc.paragraph5-1_li3")}</li>
            </ul> */}
          </div>
        </div>
      </div>
    </m.section>
  );
};

export default AmlKyc;
