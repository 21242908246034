import React, { useEffect } from 'react';
import { motion as m } from 'framer-motion';

import './components/Home.css';

import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';
import Hero from '../../components/hero/Hero';
import Benefits from './components/Benefits';
import Exchanges from './components/Exchanges';
import Steps from '../../components/steps/Steps';
import Faq from './components/Faq';

const Home = () => {
  const [t, i18n] = useTranslation('global');



useEffect(() => {
  const userId = Cookies.get('userId');
  let userIp = ''; 

  axios.get('https://api.ipify.org?format=json')
    .then(response => {
      userIp = response.data.ip; 

      // Получение значения параметра 'ref' из URL
      const urlParams = new URLSearchParams(window.location.search);
      const refParam = urlParams.get('ref') || '-'; // Если параметр не задан, используем '-'

      if (!userId) {
        const newUserId = generateUserId();
        Cookies.set('userId', newUserId, { expires: 7, sameSite: 'None', secure: true });
        ;
        sendUserDataToPhp(newUserId, userIp, refParam, 1); // Передача параметра ref в функцию
      } else {
        
        sendUserDataToPhp(userId, userIp, refParam, 0); // Передача параметра ref в функцию
      }
    })
    .catch(error => {
      

     
      const urlParams = new URLSearchParams(window.location.search);
      const refParam = urlParams.get('ref') || '-'; 

      if (!userId) {
        const newUserId = generateUserId();
        Cookies.set('userId', newUserId, { expires: 7, sameSite: 'None', secure: true });
        sendUserDataToPhp(newUserId, '-', refParam, 1); // Передача параметра ref в функцию
      } else {
        sendUserDataToPhp(userId, '-', refParam, 0); // Передача параметра ref в функцию
      }
    });
}, []);

const generateUserId = () => {
  return Math.random().toString(36).substring(2, 15);
};

const sendUserDataToPhp = (userId, userIp, refParam, status) => {
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('userIp', userIp);
  formData.append('refParam', refParam);
  formData.append('status', status);
  
  axios.post('/sendDataToPhp.php', formData)
    .then(response => {
      
    })
    .catch(error => {
     
    });
};




  return (
    <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }}>
      <Hero heroHeading1={t('hero.heading1')} heroHeading2={t('hero.heading2')} />
      <Benefits />
      <Exchanges />
      <Steps item1h4={t('steps.item1-h4')} item1span={t('steps.item1-span')} item2h4={t('steps.item2-h4')} item2span={t('steps.item2-span')} item3h4={t('steps.item3-h4')} item3span={t('steps.item3-span')} />
      <Faq />
    </m.div>
  );
};

export default Home;
