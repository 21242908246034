import React from 'react';
import { useTranslation } from 'react-i18next';

import './steps.css';

function Steps({ item1h4, item1span, item2h4, item2span, item3h4, item3span }) {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='steps'>
      <div className='container'>
        <h3 className='section-title'>{t('steps.heading3')}</h3>
        <div className='steps'>
          <div className='steps-item dfb'>
            <p>
              <span>1</span>
            </p>
            <h4>{item1h4}</h4>
            <span>{item1span}</span>
          </div>
          <div className='steps-item dfb'>
            <p>
              <span>2</span>
            </p>
            <h4>{item2h4}</h4>
            <span>{item2span}</span>
          </div>
          <div className='steps-item dfb'>
            <p>
              <span>3</span>
            </p>
            <h4>{item3h4}</h4>
            <span>{item3span}</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Steps;
