import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderLanguageSwitch = () => {
  const selectRef = useRef(null);
  const titleRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation('global');
  const localStorageLanguage = JSON.parse(localStorage.getItem('language'));
  const [language, setLanguage] = useState(localStorageLanguage || 'RU');

  useEffect(() => {
    if (localStorageLanguage) {
      i18n.changeLanguage(localStorageLanguage);
      titleRef.current.textContent = t(localStorageLanguage).toUpperCase();
    }
  }, []);

  const handleChangeLanguage = (lang) => {
    if (language !== lang) {
      i18n.changeLanguage(lang);
      setLanguage(lang);
      localStorage.setItem('language', JSON.stringify(lang));
    }
  };

  useEffect(() => {
    titleRef.current.textContent = t(language).toUpperCase();
  }, [language]);

  useEffect(() => {
    const selectElement = document.querySelector('.__select');
    if (selectElement) {
      const availableLanguages = ['en', 'ru'].filter((lang) => lang !== language.toLowerCase());
      const options = selectElement.querySelectorAll('.__select__label');
      options.forEach((option) => {
        if (availableLanguages.includes(option.getAttribute('value'))) {
          option.style.display = 'block';
        } else {
          option.style.display = 'none';
        }
      });
    }
  }, [language]);

  // Close when click to option
  const handleOptionClick = (evt) => {
    const value = evt.target.getAttribute('value');
    handleChangeLanguage(value);

    titleRef.current.textContent = evt.target.textContent;
    localStorage.setItem('language', JSON.stringify(value));

    setIsOpen(false);
  };

  // Attach event listeners
  useEffect(() => {
    const labels = Array.from(selectRef.current.querySelectorAll('.__select__label'));

    if (labels.length) {
      labels.forEach((label) => {
        label.addEventListener('click', handleOptionClick);
      });
    }

    return () => {
      labels.forEach((label) => {
        label.removeEventListener('click', handleOptionClick);
      });
    };
  }, [selectRef.current]);

  return (
    <form>
      <div ref={selectRef} className={isOpen ? '__select show' : '__select'}>
        <div className='__select__title' ref={titleRef} onClick={() => setIsOpen(!isOpen)}>
          {localStorageLanguage ? localStorageLanguage.toUpperCase() : language}
        </div>
        <div className='__select__content'>
          <input id='singleSelect1' className='__select__input' type='radio' name='singleSelect' defaultChecked />
          <label value='ru' htmlFor='singleSelect1' className='__select__label' onClick={() => handleChangeLanguage('ru')}>
            RU
          </label>
          <input id='singleSelect0' className='__select__input' type='radio' name='singleSelect' />
          <label value='en' htmlFor='singleSelect0' className='__select__label' onClick={() => handleChangeLanguage('en')}>
            EN
          </label>
        </div>
      </div>
    </form>
  );
};

export default HeaderLanguageSwitch;
