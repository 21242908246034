import React, { useState } from 'react';
import Accordion from './Accordion';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const [t, i18n] = useTranslation('global');

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const iconStyle = showMore ? { transform: 'rotate(90deg)' } : { transform: 'rotate(-90deg)' };
  let showMoreBtn = '';

  if (showMore) {
    showMoreBtn = t('faq.show-more-less');
  } else {
    showMoreBtn = t('faq.show-more-more');
  }

  return (
    <section id='faq'>
      <div className='container'>
        <h3 className='section-title'>{t('faq.heading3')}</h3>
        <div className='faq'>
          <Accordion title={t('faq.acc1-title')}>
            <p>{t('faq.acc1-p')}</p>
          </Accordion>
          <Accordion title={t('faq.acc2-title')}>
            <p>{t('faq.acc2-p')}</p>
          </Accordion>
          <Accordion title={t('faq.acc3-title')}>
            <p>{t('faq.acc3-p')}</p>
          </Accordion>
          <div className='collapsed-questions' aria-expanded={!showMore}>
            <Accordion title={t('faq.acc4-title')}>
              <p>{t('faq.acc4-p')}</p>
            </Accordion>
            <Accordion title={t('faq.acc5-title')}>
              <p>{t('faq.acc5-p')}</p>
            </Accordion>
            <Accordion title={t('faq.acc6-title')}>
              <p>{t('faq.acc6-p')}</p>
            </Accordion>
          </div>
          <button className='show-more-btn' onClick={toggleShowMore} data-bool={showMore.toString()}>
            {showMoreBtn}
            <i className={`ri-arrow-left-line ${showMore ? 'down' : ''}`}></i>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Faq;
