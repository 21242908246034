import React from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import '../About/about.css';

const PrivacyPolicy = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} id='about'>
      <div className='container'>
        <div className='about-image'>
          <h1>{t('privacy.h1')}</h1>
          <img src='/about-us-bg.jpg' alt=' no sense with heading on it' />
        </div>
        <div className='container-890'>
          <div className='about-text'>
            <p>{t('privacy.paragraph0')}</p>
            <h2 className='bigger'>{t('privacy.heading1')}</h2>
            <p>{t('privacy.paragraph1-1')}</p>
            <p>{t('privacy.paragraph1-1-1')}</p>
            <p>{t('privacy.paragraph1-1-2')}</p>
            <p>{t('privacy.paragraph1-1-3')}</p>
            <p>{t('privacy.paragraph1-1-4')}</p>
            <p>{t('privacy.paragraph1-1-5')}</p>
            <p>{t('privacy.paragraph1-1-6')}</p>
            <h2 className='bigger'>{t('privacy.heading2')}</h2>
            <p>{t('privacy.paragraph2-1')}</p>
            <p>{t('privacy.paragraph2-2')}</p>
            <p>{t('privacy.paragraph2-3')}</p>
            <p>{t('privacy.paragraph2-4')}</p>
            <h2 className='bigger'>{t('privacy.heading3')}</h2>
            <p>{t('privacy.paragraph3-1')}</p>
            <p>{t('privacy.paragraph3-2')}</p>
            <p>{t('privacy.paragraph3-3')}</p>
            <h2 className='bigger'>{t('privacy.heading4')}</h2>
            <p>{t('privacy.paragraph4-1')}</p>
            <p>{t('privacy.paragraph4-1-1')}</p>
            <p>{t('privacy.paragraph4-1-2')}</p>
            <p>{t('privacy.paragraph4-1-3')}</p>
            <p>{t('privacy.paragraph4-1-4')}</p>
            <p>{t('privacy.paragraph4-1-5')}</p>
            <p>{t('privacy.paragraph4-1-6')}</p>
            <p>{t('privacy.paragraph4-1-7')}</p>
            <p>{t('privacy.paragraph4-1-8')}</p>
            <p>{t('privacy.paragraph4-1-9')}</p>
            <p>{t('privacy.paragraph4-1-10')}</p>
            <h2 className='bigger'>{t('privacy.heading5')}</h2>
            <p>{t('privacy.paragraph5-1')}</p>
            <p>{t('privacy.paragraph5-2')}</p>
            <p>{t('privacy.paragraph5-3')}</p>
            <p>{t('privacy.paragraph5-4')}</p>
            <p>{t('privacy.paragraph5-5')}</p>
            <h2 className='bigger'>{t('privacy.heading6')}</h2>
            <p>{t('privacy.paragraph6-1')}</p>
            <p>{t('privacy.paragraph6-1-1')}</p>
            <p>{t('privacy.paragraph6-1-2')}</p>
            <p>{t('privacy.paragraph6-2')}</p>
            <p>{t('privacy.paragraph6-2-1')}</p>
            <p>{t('privacy.paragraph6-2-2')}</p>
            <p>{t('privacy.paragraph6-2-3')}</p>
            <p>{t('privacy.paragraph6-2-4')}</p>
            <h2 className='bigger'>{t('privacy.heading7')}</h2>
            <p>{t('privacy.paragraph7-1')}</p>
            <p>{t('privacy.paragraph7-2')}</p>
            <p>{t('privacy.paragraph7-3')}</p>
            <p>{t('privacy.paragraph7-4')}</p>
            <h2 className='bigger'>{t('privacy.heading8')}</h2>
            <p>{t('privacy.paragraph8-1')}</p>
            <p>{t('privacy.paragraph8-2')}</p>
            <p>{t('privacy.paragraph8-3')}</p>
            <p>{t('privacy.paragraph8-4')}</p>
            <p>
              {t('privacy.paragraph8-5')}
              <br />
              <a href='/privacy-policy' onClick={() => window.scrollTo(0, 0)} className='link'>
                http://localhost:3000/privacy-policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </m.section>
  );
};

export default PrivacyPolicy;
