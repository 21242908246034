import React from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import '../About/about.css';

const Terms = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} id='about'>
      <div className='container'>
        <div className='about-image'>
          <h1>{t('terms.h1')}</h1>
          <img src='/about-us-bg.jpg' alt='Image with no sense with heading on it' />
        </div>
        <div className='container-890'>
          <div className='about-text'>
            <p>{t('terms.paragraph1')}</p>
            <p>{t('terms.paragraph2')}</p>
            <p>{t('terms.paragraph3')}</p>
            <p>{t('terms.paragraph4')}</p>
            <p>{t('terms.paragraph5')}</p>
            <h2 className='bigger'>{t('terms.heading0')}</h2>
            <p>
              {t('terms.paragraph0-1')}
              <br />
              {t('terms.paragraph0-2')}
              <br />
              {t('terms.paragraph0-3')}
              <br />
              {t('terms.paragraph0-4')}
              <br />
              {t('terms.paragraph0-5')}
            </p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph0-5a')}</li>
              <li>{t('terms.paragraph0-5b')}</li>
              <li>{t('terms.paragraph0-5c')}</li>
              <li>{t('terms.paragraph0-5d')}</li>
            </ul>

            <p>{t('terms.paragraph0-6')}</p>
            <h2 className='bigger'>{t('terms.heading1')}</h2>
            <p>{t('terms.paragraph1-1')}</p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph1-1a')}</li>
              <li>{t('terms.paragraph1-1b')}</li>
              <li>{t('terms.paragraph1-1c')}</li>
              <li>{t('terms.paragraph1-1d')}</li>
              <li>{t('terms.paragraph1-1e')}</li>
              <li>{t('terms.paragraph1-1f')}</li>
              <li>{t('terms.paragraph1-1g')}</li>
              <li>{t('terms.paragraph1-1h')}</li>
            </ul>

            <p>{t('terms.paragraph1-2')}</p>
            <p>{t('terms.paragraph1-3')}</p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph1-3a')}</li>
              <li>{t('terms.paragraph1-3b')}</li>
              <li>{t('terms.paragraph1-3c')}</li>
              <li>{t('terms.paragraph1-3d')}</li>
              <li>{t('terms.paragraph1-3e')}</li>
              <li>{t('terms.paragraph1-3f')}</li>
              <li>{t('terms.paragraph1-3g')}</li>
              <li>{t('terms.paragraph1-3h')}</li>
              <li>{t('terms.paragraph1-3-i')}</li>
              <li>{t('terms.paragraph1-3j')}</li>
            </ul>

            <p>{t('terms.paragraph1-4')}</p>
            <p>{t('terms.paragraph1-5')}</p>
            <p>{t('terms.paragraph1-6')}</p>
            <p>{t('terms.paragraph1-7')}</p>
            <p>{t('terms.paragraph1-8')}</p>
            <p>{t('terms.paragraph1-9')}</p>
            <h2 className='bigger'>{t('terms.heading2')}</h2>
            <p>{t('terms.paragraph2-1')}</p>
            <p>{t('terms.paragraph2-2')}</p>
            <h2 className='bigger'>{t('terms.heading3')}</h2>
            <p>
              {t('terms.paragraph3-1')}
              <br />
              <a href='http://localhost:3000/terms-dex' className='link'>
                http://localhost:3000/terms-dex
              </a>
            </p>

            <h2 className='bigger'>{t('terms.heading4')}</h2>
            <p>{t('terms.paragraph4-1')}</p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph4-1a')}</li>
              <li>{t('terms.paragraph4-1b')}</li>
              <li>{t('terms.paragraph4-1c')}</li>
            </ul>
            <p>{t('terms.paragraph4-2')}</p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph4-2a')}</li>
              <li>{t('terms.paragraph4-2b')}</li>
            </ul>

            <p>{t('terms.paragraph4-3')}</p>
            <h2 className='bigger'>{t('terms.heading5')}</h2>
            <p>{t('terms.paragraph5-1')}</p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph5-1a')}</li>
              <li>{t('terms.paragraph5-1b')}</li>
            </ul>
            <p>{t('terms.paragraph5-2')}</p>
            <ul className='literal-list'>
              <li>{t('terms.paragraph5-2a')}</li>
              <li>{t('terms.paragraph5-2a-1')}</li>
              <li>{t('terms.paragraph5-2a-2')}</li>
              <li>{t('terms.paragraph5-2b')}</li>
            </ul>
            <h2 className='bigger'>{t('terms.heading6')}</h2>
            <p>{t('terms.paragraph6-1')}</p>
            <h2 className='bigger'>{t('terms.heading7')}</h2>
            <p>{t('terms.paragraph7-1')}</p>
            <p>{t('terms.paragraph7-2')}</p>
            <h2 className='bigger'>{t('terms.heading8')}</h2>
            <p>{t('terms.paragraph8-1')}</p>
            <h2 className='bigger'>{t('terms.heading9')}</h2>
            <p>{t('terms.paragraph9-1')}</p>
            <h2 className='bigger'>{t('terms.heading10')}</h2>
            <p>{t('terms.paragraph10-1')}</p>
            <h2 className='bigger'>{t('terms.heading11')}</h2>
            <p>{t('terms.paragraph11-1')}</p>
            <p>{t('terms.paragraph11-2')}</p>
          </div>
        </div>
      </div>
    </m.section>
  );
};

export default Terms;
