import React, { useEffect, useState } from 'react';
import HeaderMenu from './components/HeaderMenu';
import HeaderButtons from './components/HeaderButtons';
import BurgerMenu from './components/BurgerMenu';

import logo from '../../images/logo.svg';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 560px)').matches);
    setIsTablet(window.matchMedia('(min-width: 561px) and (max-width: 1024px)').matches);

    const handleResize = () => {
      const isMobileWidth = window.matchMedia('(max-width: 560px)').matches;
      const isTabletWidth = window.matchMedia('(min-width: 561px) and (max-width: 1024px)').matches;

      if (isMobileWidth) {
        setIsMobile(true);
        setIsTablet(false);
      } else if (isTabletWidth) {
        setIsMobile(false);
        setIsTablet(true);
      } else {
        setIsMobile(false);
        setIsTablet(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const body = document.querySelector('body');
  if (isBurgerOpen) {
    body.style.position = 'fixed';
    body.style.maxWidth = '100%';
  } else if (!isBurgerOpen) {
    body.style.position = 'static';
    body.style.maxWidth = 'auto';
  }

  return (
    <header>
      <div className='container'>
        <NavLink to='/' className='logo-link'>
          <img src={logo} alt='logo' className='logo' />
        </NavLink>
        {isMobile && (
          <BurgerMenu isOpen={isBurgerOpen} setIsOpen={setIsBurgerOpen}>
            <HeaderMenu />
            <HeaderButtons />
          </BurgerMenu>
        )}
        {isTablet && (
          <>
            <BurgerMenu isOpen={isBurgerOpen} setIsOpen={setIsBurgerOpen}>
              <HeaderMenu />
            </BurgerMenu>
            <HeaderButtons />
          </>
        )}
        {!isMobile && !isTablet && (
          <>
            <HeaderMenu />
            <HeaderButtons />
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
