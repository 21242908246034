import React, { useState } from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import './components/HowItsWork.css';

import Hero from '../../components/hero/Hero';
import TradingViewWidget from '../../api/TradingViewWidget';
import WhyChooseUs from './components/WhyChooseUs';
import Information from './components/Information';
import HowToExchange from './components/HowToExchange';

const HowItsWork = () => {
  const [t] = useTranslation('global');
  const [localStorageSecondOption, setLocalStorageSecondOption] = useState('');

  return (
    <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }}>
      <Hero heroHeading1={t('how-it-works.hero-heading1')} heroHeading2={t('how-it-works.hero-heading2')} setLocalStorageSecondOption={setLocalStorageSecondOption} />
      <TradingViewWidget />
      <WhyChooseUs />
      <Information />
      <HowToExchange />
    </m.div>
  );
};

export default HowItsWork;
