import React from 'react';

import { useTranslation } from 'react-i18next';

const HowToExchange = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='how-to-exchange'>
      <div className='container'>
        <div className='how-to-exchange'>
          <div className='title'>
            <h3>{t('how-it-works.how-to-exchange-title-h3')}</h3>
            <p>{t('how-it-works.how-to-exchange-title-p1')}</p>
            <p>{t('how-it-works.how-to-exchange-title-p2')}</p>
            <p>{t('how-it-works.how-to-exchange-title-p3')}</p>
          </div>
          <div className='steps'>
            <h3 className='steps-title'>{t('how-it-works.how-to-exchange-steps-title')}</h3>
            <div className='step'>
              <span>1</span>
              {t('how-it-works.how-to-exchange-step1')}
            </div>
            <div className='step'>
              <span>2</span>
              {t('how-it-works.how-to-exchange-step2')}
            </div>
            <div className='step'>
              <span>3</span>
              {t('how-it-works.how-to-exchange-step3')}
            </div>
            <div className='step'>
              <span>4</span>
              {t('how-it-works.how-to-exchange-step4')}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToExchange;
