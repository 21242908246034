import React from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import Hero from './components/PartnershipHero';
import Steps from '../../components/steps/Steps';
import WhyUs from './components/WhyUs';
import ContactUs from './components/ContactUs';

import './components/partnership.css';

const Partnership = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }}>
      <Hero />
      <Steps item1h4={t('partnership.steps-item1-h4')} item1span={t('partnership.steps-item1-span')} item2h4={t('partnership.steps-item2-h4')} item2span={t('partnership.steps-item2-span')} item3h4={t('partnership.steps-item3-h4')} item3span={t('partnership.steps-item3-span')} />
      <WhyUs />
      <ContactUs />
    </m.div>
  );
};

export default Partnership;
