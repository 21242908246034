import React, { forwardRef, useImperativeHandle } from 'react';

const BurgerMenu = forwardRef(({ isOpen, setIsOpen, children }, ref) => {
  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
  }));

  return (
    <div className='burger-menu'>
      <input id='burger' type='checkbox' />
      <label htmlFor='burger' onClick={() => setIsOpen(!isOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </label>

      {children}
    </div>
  );
});

export default BurgerMenu;
