import React from 'react';

import { useTranslation } from 'react-i18next';

const WhyChooseUs = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='benefits'>
      <div className='container'>
        <h3 className='section-title'>{t('benefits.heading3')}</h3>
        <div className='why-choose-us'>
          <div className='benefits-item'>
            <i className='ri-chat-private-fill mainColored'></i>
            <div className='benefits-text'>
              <h4>{t('how-it-works.why-choose-us1-title')}</h4>
              <p>{t('how-it-works.why-choose-us1-text')}</p>
            </div>
          </div>
          <div className='benefits-item'>
            <i className='ri-lock-password-fill mainColored'></i>
            <div className='benefits-text'>
              <h4>{t('how-it-works.why-choose-us2-title')}</h4>
              <p>{t('how-it-works.why-choose-us2-text')}</p>
            </div>
          </div>
          <div className='benefits-item'>
            <i className='ri-question-answer-fill mainColored'></i>
            <div className='benefits-text'>
              <h4>{t('how-it-works.why-choose-us3-title')}</h4>
              <p>{t('how-it-works.why-choose-us3-text')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
