import React from 'react';
import logo from '../../images/logo.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <footer id='footer'>
      <div className='footer-transition'></div>
      <div className='container'>
        <div className='footer'>
          <div className='footer-item'>
            <a href='/' className='logo-link'>
              <img src={logo} alt='Логотип обменной биржи' className='footer-logo logo' />
            </a>
            <p>{t('footer.slogan')}</p>
            <ul className='social'>
              <li>
                <a href='/'>
                  <i className='ri-linkedin-box-fill'></i>
                </a>
              </li>
              <li>
                <a href='/'>
                  <i className='ri-facebook-circle-fill'></i>
                </a>
              </li>
              <li>
                <a href='/'>
                  <i className='ri-instagram-fill'></i>
                </a>
              </li>
            </ul>
          </div>
          <div className='footer-item'>
            <h3 className='footer-heading'>{t('footer.list1-title')}</h3>
            <ul className='footer-list'>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/about-us'>
                  {t('footer.list1-item1')}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/aml-kyc'>
                  {t('footer.list1-item2')}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/goverment-requests-guidelines'>
                  {t('footer.list1-item3')}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/cookie-policy'>
                  {t('footer.list1-item4')}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/terms-dex'>
                  {t('footer.list1-item5')}
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/terms'>
                  {t('footer.list1-item6')}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className='footer-item'>
            <h3 className='footer-heading'>{t('footer.list2-title')}</h3>
            <ul className='footer-list'>
              <li>
                <NavLink onClick={() => window.scrollTo(0, 0)} to='/partnership'>
                  {t('footer.list2-item1')}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className='footer-item'>
            <h3 className='footer-heading'>{t('footer.list3-title')}</h3>
            <ul className='footer-list'>
              <li>
                <a href='mailto:support@unknown.com'>{t('footer.list3-item1')}</a>
              </li>
              <li>
                <a href='https://telegram.org/' target='_blank'>
                  {t('footer.list3-item2')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
