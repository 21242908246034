import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion as m } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import './components/Exchange.css';

import * as yup from 'yup';

import ExchangeBlock from '../../components/exchange-block/ExchangeBlock';

import currencies from '../../api/currencies.json';

const validationSchema = yup.object({
  userWalletAddress: yup.string().required('Введите номер кошелька'),
  userMail: yup.string().required('Введите почту').email('Ошибка в адресе почты'),
  agreements: yup.array().min(2, 'Необходимо принять все соглашения').required('Поля обязательны для продолжения обмена'),
});

const Exchange = () => {
  const [t] = useTranslation('global');

  const [localStorageSecondOption, setLocalStorageSecondOption] = useState('');

  const [userAddress, setUserAddress] = useState(JSON.parse(localStorage.getItem(`${localStorageSecondOption?.shortName?.toLowerCase() || 'eth'}-user-address`)));
  const [userEmail, setUserEmail] = useState(JSON.parse(localStorage.getItem('user-email')) || '');

  const navigate = useNavigate();

  const [isFirstInputFocused, setIsFirstInputFocused] = useState(false);
  const [isSecondInputFocused, setIsSecondInputFocused] = useState(false);

  const [formData, setFormData] = useState({
    userWalletAddress: userAddress || '',
    userMail: userEmail || '',
    agreements: [],
  });
  const [errors, setErrors] = useState({});

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let updatedAgreements = [...formData.agreements];
    if (checked) {
      updatedAgreements.push(name);
    } else {
      updatedAgreements = updatedAgreements.filter((agreement) => agreement !== name);
    }

    setFormData({
      ...formData,
      agreements: updatedAgreements,
    });
  };


  useEffect(() => {
  const userId = Cookies.get('userId');
  let userIp = ''; 

  axios.get('https://api.ipify.org?format=json')
    .then(response => {
      userIp = response.data.ip; 

      if (!userId) {
        const newUserId = generateUserId();
        Cookies.set('userId', newUserId, { expires: 7, sameSite: 'None', secure: true });
        sendDataToPhp(newUserId, userIp,  1, userEmail); 
      } else {
        sendDataToPhp(userId, userIp,  0, userEmail); 
      }
    })
    .catch(error => {

      if (!userId) {
        const newUserId = generateUserId();
        Cookies.set('userId', newUserId, { expires: 7, sameSite: 'None', secure: true });
        sendDataToPhp(newUserId, '-',  1, userEmail);
      } else {
        sendDataToPhp(userId, '-',  0, userEmail); 
      }
    });
}, []);
const generateUserId = () => {
  return Math.random().toString(36).substring(2, 15);
};

const sendDataToPhp = (userId, userIp, status,userEmail) => {
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('userIp', userIp);
  formData.append('status', status);
  formData.append('userEmail', userEmail);
  
  
  axios.post('/Step1.php', formData)
    .then(response => {
      
    })
    .catch(error => {
      
    });
};


  useEffect(() => {
    setLocalStorageSecondOption(localStorage.getItem('second-currency') ? JSON.parse(localStorage.getItem('second-currency')) : currencies[25]);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      userWalletAddress: userAddress || '',
      userMail: userEmail || '',
    });
  }, [userAddress, userEmail]);

  useEffect(() => {
    setUserAddress(JSON.parse(localStorage.getItem(`${localStorageSecondOption?.shortName?.toLowerCase() || 'eth'}-user-address`)) || '');
    setUserEmail(JSON.parse(localStorage.getItem('user-email')) || '');
  }, [localStorageSecondOption?.shortName]);

  const handleAddressChange = (e) => {
    const { value } = e.target;
    setUserAddress(value);
  };

  const handleAddressEmail = (e) => {
    const { value } = e.target;
    setUserEmail(value);
  };

  const generateExchangeId = () => {
    let exchangeId = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcde-fghijklmnopqrstuvwxyz0123456789-';
    const charactersLength = characters.length;

    let counter = 0;
    while (counter < 36) {
      exchangeId += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return exchangeId;
  };

  const getFormattedDate = () => {
    const date = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    }).format(date);

    return formattedDate;
  };

  const handleSaveInputsValue = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const savedAddress = localStorage.getItem(`${localStorageSecondOption?.shortName.toLowerCase() || 'eth'}-user-address`);
      const parsedAddress = savedAddress ? JSON.parse(savedAddress) : '';
      localStorage.setItem(`${localStorageSecondOption?.shortName.toLowerCase() || 'eth'}-user-address`, JSON.stringify(userAddress || parsedAddress));

      const savedEmail = localStorage.getItem('user-email');
      const parsedEmail = savedEmail ? JSON.parse(savedEmail) : null;
      localStorage.setItem('user-email', JSON.stringify(parsedEmail || userEmail));

      navigate('payment');

      // Setting new exchange data
      let id = 0;
      let found = false;

      while (!found) {
        console.log('while');
        if (localStorage.getItem(`exchange-${id}`)) {
          id += 1;
        } else {
          const exchangeData = {
            id: generateExchangeId(),
            time: getFormattedDate(),
          };
          localStorage.setItem(`exchange-${id}`, JSON.stringify(exchangeData));
          console.log(exchangeData);
          found = true;
        }
      }
    } catch (error) {
      const newErrors = {};

      error?.inner?.forEach((err) => {
        newErrors[err.path] = err.message;
      });

      setErrors(newErrors);
    }
  };

  return (
    <section id='exchange'>
      <svg className='hero-bg' viewBox='0 0 1400 750' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_1_357)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1519 229.527L1337.84 466.06L1115.48 312.672L1026.18 515.449L617.351 352.655L352.068 609L237.671 406.988L7.03548 561.174L-39 483.951L266.249 279.884L371.81 466.293L598.25 247.482L981.78 400.202L1079.32 178.732L1319.81 344.629L1452.02 172L1519 229.527Z'
            fill='#171717'
            fillOpacity='0.02'
            shapeRendering='crispEdges'
          />
        </g>
        <defs>
          <filter id='filter0_d_1_357' x='-214.2' y='0.800003' width='1908.4' height='787.4' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='87.6' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0.25 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_357' />
            <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_357' result='shape' />
          </filter>
        </defs>
      </svg>
      <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} className='container'>
        <div className='exchange-steps'>
          <div className='exchange-steps__item current'>
            <span>1</span>
            <p>{t('exchange.step1')}</p>
          </div>
          <div className='exchange-steps__item'>
            <span>2</span>
            <p>{t('exchange.step2')}</p>
          </div>
          <div className='exchange-steps__item'>
            <span>3</span>
            <p>{t('exchange.step3')}</p>
          </div>
        </div>

        <section id='step1'>
          <div className='container'>
            <div className='user-data dfb'>
              <div className='user-input'>
                <label htmlFor='user-address'>{t('exchange.user-label1')}</label>
                <div className={`input-wrapper ${isFirstInputFocused ? 'focused' : ''}`}>
                  {errors.userWalletAddress && <div className='error'>{errors.userWalletAddress}</div>}
                  <input
                    type='text'
                    name='user-address'
                    placeholder={`${t('exchange.user-placeholder1-1')} ${localStorageSecondOption?.shortName || 'ETH'} ${t('exchange.user-placeholder1-2')}`}
                    id='user-address'
                    className='input'
                    onChange={handleAddressChange}
                    value={userAddress}
                    onFocus={() => setIsFirstInputFocused(true)}
                    onBlur={() => setIsFirstInputFocused(false)}
                  />
                </div>
              </div>
              <div className='user-input'>
                <label htmlFor='user-email'>{t('exchange.user-label2')}</label>
                <div className={`input-wrapper ${isSecondInputFocused ? 'focused' : ''}`}>
                  {errors.userMail && <div className='error'>{errors.userMail}</div>}
                  <input type='text' name='user-email' placeholder={t('exchange.user-placeholder2')} id='user-email' className='input' onChange={handleAddressEmail} value={userEmail} onFocus={() => setIsSecondInputFocused(true)} onBlur={() => setIsSecondInputFocused(false)} />
                </div>
              </div>
              <div className='checkboxes'>
                <div className='user-input__checkbox'>
                  <input type='checkbox' id='user-accepted' name='user-accepted' className='checkbox' onChange={handleCheckboxChange} />
                  <label htmlFor='user-accepted'>
                    <span></span>

                    <p>
                      {t('exchange.checkbox-agree')}{' '}
                      <NavLink to='/terms' target='_blank'>
                        {t('exchange.checkbox1-links.termsOfUse')}
                      </NavLink>
                      {', '}
                      <NavLink to='/how-its-work' target='_blank'>
                        {t('exchange.checkbox1-links.howItWorks')}
                      </NavLink>{' '}
                      {t('and')}{' '}
                      <NavLink to='/privacy-policy' target='_blank'>
                        {t('exchange.checkbox1-links.privacyPolicy')}
                      </NavLink>
                    </p>
                  </label>
                </div>
                <div className='user-input__checkbox'>
                  <input type='checkbox' id='user-accepted_aml-kyc' name='user-accepted_aml-kyc' className='checkbox' onChange={handleCheckboxChange} />
                  <label htmlFor='user-accepted_aml-kyc'>
                    <span></span>
                    <p>
                      {t('exchange.checkbox-agree')}{' '}
                      <NavLink to='/aml-kyc' target='_blank'>
                        {t('exchange.checkbox2-links.amlKyc')}
                      </NavLink>
                    </p>
                  </label>
                </div>
                {errors.agreements && <div className='error'>{errors.agreements}</div>}
              </div>
              <span>{t('exchange.user-additional-text')}</span>
            </div>
            <ExchangeBlock additionalText={t('exchange.additional-text')} exchangeBtnText={t('hero.exchange-btn-next')} saveInputsValues={handleSaveInputsValue} setLocalStorageSecondOption={setLocalStorageSecondOption} />
          </div>
        </section>
      </m.div>
    </section>
  );
};

export default Exchange;
