import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { motion as m } from 'framer-motion';

import { getRandomInterval, getRandomTimeAgo, getRandomLength, getRandomAmount, getRandomCurrencies } from './utils';

const Exchanges = () => {
  const [t, i18n] = useTranslation('global');

  const [exchanges, setExchanges] = useState([]);
  const [sortedExchanges, setSortedExchanges] = useState([]);
  const sortedExchangesRef = useRef([]);
  const [translatedDate, setTranslatedDate] = useState('');

  const today = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const formattedDate = today.toLocaleDateString(t('langCode'), options);

  useEffect(() => {
    setTranslatedDate(
      t('dateFormat', {
        day: formattedDate.split(' ')[0],
        month: formattedDate.split(' ')[1],
        year: formattedDate.split(' ')[2],
      }),
    );
  }, [i18n.language]); // re-run effect when language changes

  const addExchange = useCallback((exchange) => {
    setExchanges((prevExchanges) => [exchange, ...prevExchanges.slice(0, 7)]);
  }, []);

  useEffect(() => {
    const translatedDate = t('dateFormat', {
      day: formattedDate.split(' ')[0],
      month: formattedDate.split(' ')[1],
      year: formattedDate.split(' ')[2],
    });

    setTranslatedDate(translatedDate);

    const initialExchanges = Array.from({ length: 8 }, () => {
      const exchange = {
        id: Date.now() + Math.random(),
        timeAgo: translatedDate,
        amount: getRandomAmount().toFixed(getRandomLength()),
        currencies: getRandomCurrencies(),
      };

      return exchange;
    });

    setExchanges(initialExchanges);

    let intervalId;

    const generateNewInterval = () => {
      const randomInterval = getRandomInterval();
      intervalId = setInterval(() => {
        const exchange = {
          id: Date.now() + Math.random(),
          timeAgo: translatedDate,
          amount: getRandomAmount().toFixed(getRandomLength()),
          currencies: getRandomCurrencies(),
        };

        addExchange(exchange);
      }, randomInterval);
    };

    generateNewInterval();

    return () => clearInterval(intervalId);
  }, [translatedDate]);

  useEffect(() => {
    sortedExchangesRef.current = [...exchanges].sort((a, b) => a.timeAgo - b.timeAgo);
  }, [exchanges]);

  useEffect(() => {
    setSortedExchanges(sortedExchangesRef.current);
  }, [exchanges]);

  return (
    <section id='exchanges'>
      <div className='container'>
        <h3 className='section-title'>{t('exchanges.heading3')}</h3>
        <div className='exchanges'>
          {exchanges.map((exchange) => (
            <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }} className='exchange-item dfb' key={exchange.id}>
              <p className='exchange-time'>
                {exchange.timeAgo}
                {/* {t("exchanges.ago-time")} */}
              </p>
              <h4 className='exchange-amount'>
                {exchange.amount} {exchange.currencies[1].shortName}
              </h4>
              <div className='currencies'>
                <span>
                  <img src={exchange.currencies[0].imageURL} alt='' /> {exchange.currencies[0].shortName}
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                    <path
                      d='M4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H13.25V18C13.25 18.3034 13.4327 18.5768 13.713 18.6929C13.9932 18.809 14.3158 18.7449 14.5303 18.5304L20.5303 12.5304C20.671 12.3897 20.75 12.1989 20.75 12C20.75 11.8011 20.671 11.6103 20.5303 11.4697L14.5303 5.46969C14.3158 5.25519 13.9932 5.19103 13.713 5.30711C13.4327 5.4232 13.25 5.69668 13.25 6.00002V11.25H4Z'
                      fill='#68FFC8'
                    />
                  </svg>
                  <img src={exchange.currencies[1].imageURL} alt='' /> {exchange.currencies[1].shortName}
                </span>
              </div>
            </m.div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default Exchanges;
