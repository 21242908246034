import React from 'react';
import { motion as m } from 'framer-motion';

import { useTranslation } from 'react-i18next';

import '../About/about.css';

const CookiePolicy = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} id='about'>
      <div className='container'>
        <div className='about-image'>
          <h1>{t('cookie.h1')}</h1>
          <img src='/about-us-bg.jpg' alt='Image with no sense with heading on it' />
        </div>
        <div className='container-890'>
          <div className='about-text'>
            <p>{t('cookie.paragraph1')}</p>
            <p>{t('cookie.paragraph2')}</p>
            <h2 className='bigger'>{t('cookie.heading1')}</h2>
            <p>{t('cookie.paragraph1-2')}</p>
            <h2 className='bigger'>{t('cookie.heading2')}</h2>
            <p>{t('cookie.paragraph2-1')}</p>
            <h2 className='bigger'>{t('cookie.heading3')}</h2>
            <p>{t('cookie.paragraph3')}</p>
          </div>
        </div>
      </div>
    </m.section>
  );
};

export default CookiePolicy;
