import React from 'react';

import { useTranslation } from 'react-i18next';

const WhyUs = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='why-us'>
      <div className='container'>
        <div className='why-us'>
          <div className='block'>
            <h3 className='section-title'>{t('partnership.why-us-section-heading3')}</h3>
          </div>
          <div className='block'>
            <i className='ri-apps-2-fill mainColored'></i>
            <b>1</b>
            <h3>{t('partnership.why-us1-heading3')}</h3>
            <span>{t('partnership.why-us1-span')}</span>
          </div>
          <div className='block'>
            <i className='ri-money-dollar-circle-fill mainColored'></i>
            <b>2</b>
            <h3>{t('partnership.why-us2-heading3')}</h3>
            <span>{t('partnership.why-us2-span')}</span>
          </div>
          <div className='block'>
            <i className='ri-chat-3-fill mainColored'></i>
            <b>3</b>
            <h3>{t('partnership.why-us3-heading3')}</h3>
            <span>{t('partnership.why-us3-span')}</span>
          </div>
          <div className='block'>
            <i className='ri-dashboard-fill mainColored'></i>
            <b>4</b>
            <h3>{t('partnership.why-us4-heading3')}</h3>
            <span>{t('partnership.why-us4-span')}</span>
          </div>
          <div className='block'>
            <i className='ri-checkbox-circle-fill mainColored'></i>
            <b>5</b>
            <h3>{t('partnership.why-us5-heading3')}</h3>
            <span>{t('partnership.why-us5-span')}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
