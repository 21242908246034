import React from 'react';

import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const [t, i18n] = useTranslation('global');

  return (
    <section id='contact-us'>
      <div className='container'>
        <h3 className='section-title'>{t('partnership.contact-us-heading3')}</h3>
        <div className='contact-us'>
          <a href='/' className='contact-item'>
            <i className='ri-mail-fill mainColored'></i>
            <i className='ri-arrow-right-line'></i>
            <h3>{t('partnership.contact-us-mail')}</h3>
          </a>
          <a href='/' className='contact-item'>
            <i className='ri-telegram-fill mainColored'></i>
            <i className='ri-arrow-right-line'></i>
            <h3>Telegram</h3>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
