import React, { useEffect } from 'react';
import { motion as m } from 'framer-motion';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import './about.css';

import currenciesArray from '../../api/currencies.json';

const About = () => {
  const [t, i18n] = useTranslation('global');

  const currencies = currenciesArray.map((item) => (
    <li className='currency-item' key={item.id}>
      <div className='currency-item__inset'>
        <img src={item.imageURL} alt={item.name} />
        <h3>{item.name}</h3>
      </div>
    </li>
  ));
  useEffect(() => {
    const currencyEls = document.querySelectorAll('.currency-item');
    document.querySelector('body').addEventListener('pointermove', (ev) => {
      currencyEls.forEach((currencyEl) => {
        // Not optimized yet, I know
        const rect = currencyEl.getBoundingClientRect();
        currencyEl.style.setProperty('--x', ev.clientX - rect.left);
        currencyEl.style.setProperty('--y', ev.clientY - rect.top);
      });
    });
  }, []);

  return (
    <m.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6 }} id='about'>
      <div className='container'>
        <div className='about-image'>
          <h1>{t('about.h1')}</h1>
          <img src='/about-us-bg.jpg' alt='Image with no sense with heading on it' />
        </div>
        <div className='container-890'>
          <div className='about-text'>
            <h2 className='bigger'>{t('about.mission')}</h2>
            <p>{t('about.mission-p')}</p>
            <h2 className='bigger'>{t('about.benefits')}</h2>
            <p>{t('about.benefits-p')}</p>
            <h2>{t('about.benefit1-heading')}</h2>
            <p>{t('about.benefit1-p')}</p>
            <h2>{t('about.benefit2-heading')}</h2>
            <p>{t('about.benefit2-p')}</p>
            <h2>{t('about.benefit3-heading')}</h2>
            <p>{t('about.benefit3-p')}</p>
            <h2>{t('about.benefit4-heading')}</h2>
            <p>{t('about.benefit4-p')}</p>
            <h2>{t('about.benefit5-heading')}</h2>
            <p>{t('about.benefit5-p')}</p>
            <h2>{t('about.benefit6-heading')}</h2>
            <p>{t('about.benefit6-p')}</p>
            <h2>{t('about.benefit7-heading')}</h2>
            <p>{t('about.benefit7-p')}</p>
          </div>
          <div className='about-currencies'>
            <h2>{t('about.currencies')}</h2>
            <ul className='currencies'>{currencies}</ul>
            <NavLink to='/exchange' className='btn main-color-btn'>
              {t('about.button')} <i className='ri-arrow-right-line'></i>
            </NavLink>
          </div>
        </div>
      </div>
    </m.section>
  );
};

export default About;
