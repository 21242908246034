import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import './components/exchangeBlock.css';

import * as yup from 'yup';

import currencies from '../../api/currencies.json';

const validationSchema = yup.object({
  firstInput: yup.string().required('Введите сумму, которую хотите обменять'),
  secondInput: yup.string().required('Поле не может быть пустым'),
  isAvailable: yup.boolean().required('Выберите другую пару валют для обмена'),
});

const ExchangeBlock = ({ additionalText, exchangeBtnText, navTo, saveInputsValues, setLocalStorageSecondOption }) => {
  const [t, i18n] = useTranslation('global');

  const inputFirstRef = useRef(null);
  const inputSecondRef = useRef(null);

  const localStorageFirstOption = JSON.parse(localStorage.getItem('first-currency'));
  const localStorageSecondOption = JSON.parse(localStorage.getItem('second-currency'));
  const localStorageFirstInputValue = JSON.parse(localStorage.getItem('first-input'));
  const localStorageSecondInputValue = JSON.parse(localStorage.getItem('second-input'));

  const [isFirstInputFocused, setIsFirstInputFocused] = useState(false);
  const [isSecondInputFocused, setIsSecondInputFocused] = useState(false);

  const [ratesData, setRatesData] = useState([]);

  const [isAvailable, setIsAvailable] = useState(true);
  const [currencyPrice, setCurrencyPrice] = useState(0);

  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);

  const [isFirstFocused, setIsFirstFocused] = useState(false);
  const [isSecondFocused, setIsSecondFocused] = useState(false);

  const [firstInputValue, setFirstInputValue] = useState(localStorageFirstInputValue || '');
  const [secondInputValue, setSecondInputValue] = useState(localStorageSecondInputValue || '');

  const [firstCurrencyName, setFirstCurrencyName] = useState('');
  const [secondCurrencyName, setSecondCurrencyName] = useState('');

  const [firstSelectedOption, setFirstSelectedOption] = useState(localStorageFirstOption || currencies[9]);
  const [secondSelectedOption, setSecondSelectedOption] = useState(localStorageSecondOption || currencies[22]);

  const [firstFilteredCurrencies, setFirstFilteredCurrencies] = useState(currencies);
  const [secondFilteredCurrencies, setSecondFilteredCurrencies] = useState(currencies);
  const [result, setResult] = useState(0);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstInput: firstInputValue || '',
    secondInput: secondInputValue || '',
    isAvailable: isAvailable || false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData({
      ...formData,
      firstInput: firstInputValue || '',
      secondInput: secondInputValue || '',
      isAvailable: isAvailable || false,
    });
  }, [firstInputValue, secondInputValue, isAvailable]);

  const pair = firstSelectedOption.shortName + secondSelectedOption.shortName;
  const reversedPair = secondSelectedOption.shortName + firstSelectedOption.shortName;

  const pairItem = ratesData.find((item) => item.symbol === pair);
  const reversedPairItem = ratesData.find((item) => item.symbol === reversedPair);

  const existingRatesPair = pairItem || reversedPairItem;

  useEffect(() => {
    const fetchData = async () => {
      const userId = Cookies.get('userId');
      if (!userId) return;
      const formfData = new FormData();
      formfData.append('userId', userId);
      try {
        const response = await axios.post('/getcurse.php', formfData);
        setResult(response.data);
        
      } catch (error) {
        console.error('Error', error);
      }
    };

    fetchData();
  }, []);




  const handleSetCurrencyPrice = () => {
    if (ratesData.length === 0) {
      return;
    }

    if (existingRatesPair?.symbol === pair) {
      setCurrencyPrice(existingRatesPair.price * (1 + parseFloat(result)));
      setIsAvailable(true);
    } else if (existingRatesPair?.symbol === reversedPair) {
      setCurrencyPrice(parseFloat(1 / existingRatesPair.price) * (1 + parseFloat(result)));
      setIsAvailable(true);
    } else if (existingRatesPair?.symbol !== pair && existingRatesPair?.symbol !== reversedPair) {
      
      const firstCurrencyToUsdt = ratesData.find((item) => item?.symbol === firstSelectedOption.shortName + 'USDT');
      const secondCurrencyToUsdt = ratesData.find((item) => item?.symbol == secondSelectedOption.shortName + 'USDT');

      setCurrencyPrice((firstCurrencyToUsdt.price / secondCurrencyToUsdt.price) * (1 + parseFloat(result)));
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
    }
  };

  useEffect(() => {
    if (isAvailable) {
      setFirstInputValue(localStorageFirstInputValue || '');
      setSecondInputValue(localStorageSecondInputValue || '');
    }
  }, []);

  const toggleFirstDropdown = () => {
    setIsFirstOpen(!isFirstOpen);
  };
  const toggleSecondDropdown = () => {
    setIsSecondOpen(!isSecondOpen);
  };

  const toggleFirstFocus = (event) => {
    event.stopPropagation();
    setIsFirstFocused(!setIsFirstFocused);
  };
  const toggleSecondFocus = (event) => {
    event.stopPropagation();
    setIsSecondFocused(!isSecondFocused);
  };

  const handleFirstInputFocus = () => {
    setIsFirstFocused(true);
    inputFirstRef.current.setAttribute('focus', 'true');
  };
  const handleSecondInputFocus = () => {
    setIsSecondFocused(true);
    inputSecondRef.current.setAttribute('focus', 'true');
  };

  const handleFirstOptionClick = (currency) => {
    setFirstSelectedOption(currency);
    localStorage.setItem('first-currency', JSON.stringify(currency));
    setIsFirstFocused(false);
    setIsFirstOpen(false);
    setFirstInputValue('');
    setSecondInputValue('');
  };
  const handleSecondOptionClick = (currency) => {
    setSecondSelectedOption(currency);
    setLocalStorageSecondOption(currency);
    localStorage.setItem('second-currency', JSON.stringify(currency));
    setIsSecondFocused(false);
    setIsSecondOpen(false);
    setFirstInputValue('');
    setSecondInputValue('');
  };

  const handleFirstInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    setFirstInputValue(parsedValue);
    if (isAvailable) {
      setSecondInputValue(parsedValue * currencyPrice);
    } else {
      setSecondInputValue('');
    }
  };

  const handleSecondInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    setSecondInputValue(parsedValue);
    if (isAvailable) {
      setFirstInputValue(parsedValue / currencyPrice);
    } else {
      setFirstInputValue('');
    }
  };

  const handleDocumentClick = (event) => {
    const dropdownElements = document.querySelectorAll('.select-currency');
    const optionElements = document.querySelectorAll('.option');

    if (!Array.from(dropdownElements).some((dropdownElement) => dropdownElement.contains(event.target)) && !Array.from(optionElements).some((optionElement) => optionElement.contains(event.target))) {
      setIsFirstOpen(false);
      setIsSecondOpen(false);
      setIsFirstFocused(false);
      setIsSecondFocused(false);
    }
  };

  const handleCurrencySwitch = (event) => {
    setFirstSelectedOption(secondSelectedOption);
    setSecondSelectedOption(firstSelectedOption);
    setLocalStorageSecondOption(firstSelectedOption);

    localStorage.setItem('first-currency', JSON.stringify(secondSelectedOption));
    localStorage.setItem('second-currency', JSON.stringify(firstSelectedOption));
    handleFirstInputChange(event);
    handleSecondInputChange(event);
    setFirstInputValue(secondInputValue);
    setSecondInputValue(firstInputValue);

    localStorage.setItem('first-input', JSON.stringify(secondInputValue));
    localStorage.setItem('second-input', JSON.stringify(firstInputValue));
  };

  const handleButtonClick = async (saveInputsValues) => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      window.scrollTo(0, 0);

      localStorage.setItem('first-input', JSON.stringify(firstInputValue));
      localStorage.setItem('second-input', JSON.stringify(secondInputValue));
      saveInputsValues();
      if (isAvailable) {
        navigate('/exchange');
      }
    } catch (error) {
      const newErrors = {};

      error?.inner?.forEach((err) => {
        newErrors[err.path] = err.message;
      });

      setErrors(newErrors);
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch(`https://api3.binance.com/api/v3/ticker/price`);
        const data = await response.json();
        setRatesData(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPrices().then();
  }, []);

  useEffect(() => {
    handleSetCurrencyPrice();
  }, [ratesData, firstSelectedOption, secondSelectedOption, result]);

  const onFirstInputChange = (event) => {
    setFirstCurrencyName(event.target.value);
  };
  const onSecondInputChange = (event) => {
    setSecondCurrencyName(event.target.value);
  };
  useEffect(() => {
    setFirstFilteredCurrencies(
      currencies.filter(
        (currency) =>
          (currency.name.toLowerCase().includes(firstCurrencyName.toLowerCase()) || currency.shortName.toLowerCase().includes(firstCurrencyName.toLowerCase())) &&
          (currency?.web !== firstSelectedOption?.web || currency.shortName !== firstSelectedOption.shortName) &&
          (currency.shortName !== secondSelectedOption.shortName || currency.web !== secondSelectedOption.web),
      ),
    );
  }, [firstCurrencyName, firstSelectedOption, secondSelectedOption]);

  useEffect(() => {
    setSecondFilteredCurrencies(
      currencies.filter(
        (currency) =>
          (currency.name.toLowerCase().includes(secondCurrencyName.toLowerCase()) || currency.shortName.toLowerCase().includes(secondCurrencyName.toLowerCase())) &&
          (currency?.web !== secondSelectedOption?.web || currency.shortName !== secondSelectedOption.shortName) &&
          (currency.shortName !== firstSelectedOption.shortName || currency.web !== firstSelectedOption.web),
      ),
    );
  }, [secondCurrencyName, firstSelectedOption, secondSelectedOption]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  });

  return (
    <div className='hero-exchange'>
      <div className='exchange-from dfb'>
        <h3>
          {t('hero.exchange-from')} <i className='ri-arrow-up-circle-fill mainColored'></i>
        </h3>
        <div className={`input-wrapper ${isFirstInputFocused ? 'focused' : ''}`}>
          {errors.firstInput && <div className='error'>{errors.firstInput}</div>}
          <input
            className='input'
            type='number'
            inputMode='numeric'
            pattern='[0-9\s]{13,19}'
            placeholder='0,0'
            min='0'
            step='0.01'
            value={firstInputValue}
            onChange={handleFirstInputChange}
            onFocus={() => setIsFirstInputFocused(true)}
            onBlur={() => setIsFirstInputFocused(false)}
          />
          <div className='select-currency'>
            <div className='selected-option' onClick={toggleFirstDropdown} data-is-open={!!isFirstOpen}>
              <img src={firstSelectedOption.imageURL} alt={firstSelectedOption.shortName} />
              <span>{firstSelectedOption.shortName}</span>
              {firstSelectedOption.web ? <span className='web'>{firstSelectedOption.web.toLocaleUpperCase()}</span> : null}
            </div>
            {isFirstOpen && (
              <ul className='options'>
                <div className='currency-search'>
                  <label htmlFor='currency' className={`currency-search-field ${isFirstFocused ? 'focused' : ''}`}>
                    <input type='text' id='currency' placeholder='Введите название или сокращение валюты...' ref={inputFirstRef} onFocus={handleFirstInputFocus} onChange={onFirstInputChange} value={firstCurrencyName} />
                    <i className='ri-search-line mainColored' onClick={toggleFirstFocus}></i>
                  </label>
                  <h3>Валюта</h3>
                </div>
                {firstFilteredCurrencies.length > 0
                  ? firstFilteredCurrencies.map((currency) => (
                      <li key={currency.id} className={`option ${firstSelectedOption === currency ? 'selected' : ''}`} onClick={() => handleFirstOptionClick(currency)}>
                        <img src={currency.imageURL} alt={currency.shortName} />
                        <span>{currency.shortName}</span>
                        {currency.web ? <span className='web'>{currency.web.toLocaleUpperCase()}</span> : null}
                        <span>{currency.name}</span>
                      </li>
                    ))
                  : 'No matches result'}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className='exchange-swap dfb'>
        <p className='exchange-values'>
          {isAvailable ? `1 ${firstSelectedOption.shortName} ~ ${currencyPrice} ${secondSelectedOption.shortName}` : 'Обмен пары невозможен'}
          {errors.isAvailable && <div className='error'>{errors.isAvailable}</div>}
        </p>
        <div className='svg-container' onClick={handleCurrencySwitch}>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path
              d='M12 13.125C12.3013 13.125 12.5733 13.3052 12.6907 13.5827C12.8081 13.8601 12.7482 14.1808 12.5384 14.3971L8.53844 18.5221C8.39719 18.6678 8.20293 18.75 8.00002 18.75C7.79711 18.75 7.60285 18.6678 7.46159 18.5221L3.46159 14.3971C3.25188 14.1808 3.19192 13.8601 3.30934 13.5827C3.42676 13.3052 3.69877 13.125 4.00002 13.125H7.25002L7.25002 6C7.25002 5.58579 7.5858 5.25 8.00002 5.25C8.41423 5.25 8.75002 5.58579 8.75002 6V13.125L12 13.125Z'
              fill='#68FFC8'
            />
            <path
              d='M20 10.875C20.3013 10.875 20.5733 10.6948 20.6907 10.4173C20.8081 10.1399 20.7482 9.81916 20.5384 9.60289L16.5384 5.47789C16.3972 5.33222 16.2029 5.25 16 5.25C15.7971 5.25 15.6029 5.33222 15.4616 5.47789L11.4616 9.60289C11.2519 9.81916 11.1919 10.1399 11.3093 10.4173C11.4268 10.6948 11.6988 10.875 12 10.875H15.25V18C15.25 18.4142 15.5858 18.75 16 18.75C16.4142 18.75 16.75 18.4142 16.75 18L16.75 10.875L20 10.875Z'
              fill='#68FFC8'
            />
          </svg>
        </div>

        {/*<form className="exchange-type" name='type'>*/}
        {/*    <input type="radio" id="rdo" value='float' name='exchange' defaultChecked/>*/}
        {/*    <label htmlFor="rdo">{t('hero.exchange-type1')}</label>*/}
        {/*    <input type="radio" id="rda" value='fixed' name='exchange'/>*/}
        {/*    <label htmlFor="rda">{t('hero.exchange-type2')}</label>*/}
        {/*</form>*/}
      </div>

      <div className='exchange-to dfb'>
        <h3>
          {t('hero.exchange-to')} <i className='ri-arrow-down-circle-fill mainColored'></i>
        </h3>
        <div className={`input-wrapper ${isSecondInputFocused ? 'focused' : ''}`}>
          {errors.secondInput && <div className='error'>{errors.secondInput}</div>}
          <input
            className='input'
            type='number'
            inputMode='numeric'
            pattern='[0-9\s]{13,19}'
            placeholder='0,0'
            min='0'
            step='0.01'
            value={secondInputValue}
            onChange={handleSecondInputChange}
            onFocus={() => setIsSecondInputFocused(true)}
            onBlur={() => setIsSecondInputFocused(false)}
          />
          <div className='select-currency'>
            <div className='selected-option' onClick={toggleSecondDropdown} data-is-open={!!isSecondOpen}>
              <img src={secondSelectedOption.imageURL} alt={secondSelectedOption.shortName} />
              <span>{secondSelectedOption.shortName}</span>
              {secondSelectedOption.web ? <span className='web'>{secondSelectedOption.web.toLocaleUpperCase()}</span> : null}
            </div>
            {isSecondOpen && (
              <ul className='options'>
                <div className='currency-search'>
                  <label htmlFor='currency' className={`currency-search-field ${isSecondFocused ? 'focused' : ''}`}>
                    <input type='text' id='currency' placeholder='Введите название или сокращение валюты...' ref={inputSecondRef} onFocus={handleSecondInputFocus} onChange={onSecondInputChange} value={secondCurrencyName} />
                    <i className='ri-search-line mainColored' onClick={toggleSecondFocus}></i>
                  </label>
                  <h3>Валюта</h3>
                </div>
                {secondFilteredCurrencies.length > 0
                  ? secondFilteredCurrencies.map((currency) => (
                      <li key={currency.id} className={`option ${secondSelectedOption === currency ? 'selected' : ''} `} onClick={() => handleSecondOptionClick(currency)}>
                        <img src={currency.imageURL} alt={currency.shortName} />
                        <span>{currency.shortName}</span>
                        {currency.web ? <span className='web'>{currency.web.toLocaleUpperCase()}</span> : null}
                        <span>{currency.name}</span>
                      </li>
                    ))
                  : 'No matches result'}
              </ul>
            )}
          </div>
        </div>
        {additionalText ? <span style={{ fontWeight: '400', fontSize: '0.9rem', color: '#cfcfcf' }}>{additionalText}</span> : ''}
      </div>
      <NavLink className='btn main-color-btn' onClick={() => handleButtonClick(saveInputsValues)} to={navTo}>
        {exchangeBtnText} <i className='ri-arrow-right-line'></i>
      </NavLink>
    </div>
  );
};

export default ExchangeBlock;
