import React from 'react';
import { useTranslation } from 'react-i18next';

const Page404 = () => {
  const [t, i18n] = useTranslation('global');

  const container = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px',
    padding: '3rem',
  };
  const h1 = {
    fontSize: '5rem',
    textShadow: '2px 2px 2px #FFF',
    letterSpacing: '-2px',
    textAlign: 'center',
  };
  const h2 = {
    fontSize: '1.5rem',
    textAlign: 'center',
  };

  return (
    <section>
      <div className='container' style={container}>
        <h3>{t('404.h3')}</h3>
        <h1 style={h1}>404</h1>
        <h2 style={h2}>{t('404.h2')}</h2>
      </div>
    </section>
  );
};

export default Page404;
