import React, { useState } from 'react';
import './hero.css';
import { useTranslation } from 'react-i18next';
import ExchangeBlock from '../exchange-block/ExchangeBlock';

const Hero = ({ heroHeading1, heroHeading2 }) => {
  const [t, i18n] = useTranslation('global');

  const [localStorageSecondOption, setLocalStorageSecondOption] = useState('');

  return (
    <section id='hero'>
      <svg className='hero-bg' viewBox='0 0 1400 750' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_1_357)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1519 229.527L1337.84 466.06L1115.48 312.672L1026.18 515.449L617.351 352.655L352.068 609L237.671 406.988L7.03548 561.174L-39 483.951L266.249 279.884L371.81 466.293L598.25 247.482L981.78 400.202L1079.32 178.732L1319.81 344.629L1452.02 172L1519 229.527Z'
            fill='#171717'
            fillOpacity='0.02'
            shapeRendering='crispEdges'
          />
        </g>
        <defs>
          <filter id='filter0_d_1_357' x='-214.2' y='0.800003' width='1908.4' height='787.4' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='87.6' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0.25 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1_357' />
            <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1_357' result='shape' />
          </filter>
        </defs>
      </svg>
      {/* <img src={heroBG} alt="Фоновое изображение"  /> */}
      <div className='container'>
        <div className='hero-text'>
          <h1>{heroHeading1}</h1>
          <h2>{heroHeading2}</h2>
        </div>
        <ExchangeBlock exchangeBtnText={t('hero.exchange-btn')} navTo='/exchange' setLocalStorageSecondOption={setLocalStorageSecondOption} />
      </div>
    </section>
  );
};

export default Hero;
