import React, { useState } from 'react';

export default (props) => {
  const { title } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='accordion dfb' onClick={toggle}>
      <div className='accordion-title'>
        <span>{title}</span>
        <i className='toggle ri-add-fill mainColored' aria-expanded={isOpen}></i>
      </div>
      <div className='accordion-content' aria-expanded={!isOpen}>
        {props.children}
      </div>
    </div>
  );
};
