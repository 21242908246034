import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/main.css';
import 'remixicon/fonts/remixicon.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import global_en from './translations/en/global.json';
import global_ru from './translations/ru/global.json';
import i18next from 'i18next';

i18next.init({
  interpolation: { escapeValue: false },
  lng: JSON.parse(localStorage.getItem('language')) || 'ru',
  resources: {
    en: {
      global: global_en,
    },
    ru: {
      global: global_ru,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
);

reportWebVitals();
