import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { motion as m } from 'framer-motion';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import HowItsWork from './pages/HowItsWork/HowItsWork';
import DefiSwap from './pages/DefiSwap';
import Partnership from './pages/Partnership/Partnership';
import Page404 from './pages/404';
import Exchange from './pages/Exchange/Exchange';
import ExchangeStep2 from './pages/Exchange/components/ExchangeStep2';
import ExchangeStep3 from './pages/Exchange/components/ExchangeStep3';
import ExchangeFail from './pages/Exchange/components/ExchangeFail';

import AmlKyc from './pages/TextPages/AmlKyc';
import GovermentRequests from './pages/TextPages/GovermentRequests';
import CookiePolicy from './pages/TextPages/CookiePolicy';
import TermsDex from './pages/TextPages/TermsDex';
import Terms from './pages/TextPages/Terms';
import PrivacyPolicy from './pages/TextPages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <Header />
      <m.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
        <Routes>
          <Route index element={<Home />} />
          <Route exact path='/about-us' element={<About />} />
          <Route exact path='/how-its-work' element={<HowItsWork />} />
          {/* <Route exact path='/defi-swap' element={<DefiSwap />} /> */}
          <Route exact path='/partnership' element={<Partnership />} />
          <Route exact path='/exchange' element={<Exchange />} />
          <Route exact path='/exchange/payment' element={<ExchangeStep2 />} />
          <Route exact path='/exchange/success' element={<ExchangeStep3 />} />
          <Route exact path='/exchange/exchange-rejected' element={<ExchangeFail />} />
          {/* text pages */}
          <Route exact path='/aml-kyc' element={<AmlKyc />} />
          <Route exact path='/goverment-requests-guidelines' element={<GovermentRequests />} />
          <Route exact path='/cookie-policy' element={<CookiePolicy />} />
          <Route exact path='/terms-dex' element={<TermsDex />} />
          <Route exact path='/terms' element={<Terms />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='*' element={<Page404 />} />
        </Routes>
      </m.main>
      <Footer />
    </Router>
  );
}

export default App;
